<ng-container *ngIf="validFieldsWithData.length > 0">
  <h2>Valid Fields</h2>
  <table>
    <thead>
      <tr>
        <th>Field Name</th>
        <th>Data Input</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of validFieldsWithData">
        <td width="350px">{{ field.fieldName }}</td>
        <td>{{ field.value }}</td>
      </tr>
    </tbody>
  </table>
</ng-container>
