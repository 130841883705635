<form [formGroup]="passwordForm" class="password-form">
  <h1>Reset Password</h1>
  <div class="input-group">
    <label for="current_password">Current Password</label>
    <div class="password-input">
      <input
        [type]="showPassword ? 'text' : 'password'"
        name="current_password"
        id="current_password"
        formControlName="current_password"
        data-cy="current_password"
      />
      <button
        data-cy="show-password"
        (click)="showPassword = !showPassword"
        class="toggle-password eye-button {{
          showPassword ? 'closed' : 'open'
        }}"
      >
        {{ showPassword ? 'hide password' : 'show password' }}
      </button>
    </div>
    <label for="password">New Password</label>
    <div class="password-input">
      <input
        [type]="showPassword ? 'text' : 'password'"
        name="password"
        id="password"
        formControlName="password"
        data-cy="password"
      />
      <button
        data-cy="show-password"
        (click)="showPassword = !showPassword"
        class="toggle-password eye-button {{
          showPassword ? 'closed' : 'open'
        }}"
      >
        {{ showPassword ? 'hide password' : 'show password' }}
      </button>
    </div>
    <label for="password_confirmation">Password Confirmation</label>
    <div class="password-input">
      <input
        [type]="showPassword ? 'text' : 'password'"
        name="password_confirmation"
        id="password_confirmation"
        formControlName="password_confirmation"
        data-cy="password_confirmation"
      />
      <button
        data-cy="show-password"
        (click)="showPassword = !showPassword"
        class="toggle-password eye-button {{
          showPassword ? 'closed' : 'open'
        }}"
      >
        {{ showPassword ? 'hide password' : 'show password' }}
      </button>
    </div>
  </div>
  <div class="result error">{{ result$ | async }}</div>
  <button
    [disabled]="passwordForm.status === 'INVALID'"
    type="button"
    data-cy="reset-button"
    (click)="resetPassword()"
  >
    Reset Password
  </button>
</form>
