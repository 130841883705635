<section>
  <h2>File Information</h2>
  <dl class="parent">
    <dt>Provider:</dt>
    <dd>
      <a routerLink="/provider-gateway/provider-profile/{{ providerId }}">{{
        providerName
      }} <ng-container *ngIf="providerActiveStatus === 'inactive'">(Inactive)</ng-container></a>
    </dd>
    <dt>Reporting Period:</dt>
    <dd>{{ reportingPeriod }}</dd>
    <dt>File Name:</dt>
    <dd>
      <a
        *ngIf="submissionId; else plainText"
        [routerLink]="['/provider-gateway/submission-history', submissionId]"
        >{{ fileName }} </a
      ><ng-template #plainText>
        <span>{{ fileName }}</span>
      </ng-template>
    </dd>
    <dt>Submission Date:</dt>
    <dd>{{ submissionDate | date : 'short' }}</dd>
  </dl>
</section>
