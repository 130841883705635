<ul class="breadcrumbs">
  <li>
    <a routerLink="/data-governance" routerLinkActive="active"
      >Data Governance</a
    >
  </li>
  <li>ITE Naming Conventions</li>
</ul>
<h1>ITE Naming Conventions</h1>
<ul>
  <li>
    Use <b>full descriptive words</b> whenever possible (e.g.,
    provider_type_code, diagnosis_code, etc.) Use an abbreviation, only when
    it's commonly used or the word is too long. In those cases, any
    abbreviations used should be added to the data dictionary (e.g., smi, sed,
    cafas, etc.)
  </li>
  <li>
    Do <b>NOT use 'space' or any special characters</b> in any object names.
  </li>
  <li>
    Use the <b>snake_case</b> rule: write all characters in lowercase with an
    underscore for composite names, to name all database objects, including
    databases, schemas, tables, views, and column names. This is per naming
    convention rules governed by Ruby on Rails, which is one of the most popular
    web application development frameworks, used to develop the ITE portal and
    Provider Gateway.
  </li>
  <li>
    Table names should be plural (e.g., clients, episodes, diagnoses, etc.)
  </li>
  <li>
    Column names should be <b>singular_snake_case</b> (e.g., first_name,
    date_of-birth, claim_id, etc.)
  </li>
  <li>
    A foreign key column should be <b>singularized_table_name</b> (e.g.,
    client_id for <i>clients</i> table, claim_id for <i>claims</i> table,
    address_id for <i>addresses</i> table, etc.)
  </li>
  <li>
    Data analysts or scientists creating data reports or dashboards may
    translate column names into different words or in different styles that may
    be more accustomed to the business or the audience of the data reports. In
    that case, the original names of the source objects will not change, and it
    is recommended to document translations whenever possible.
  </li>
  <li>Apply the same rules <b>consistently</b>.</li>
</ul>
<p class="secondary">Updated on 3/1/2023</p>
