<ul class="breadcrumbs">
  <li>
    <a routerLink="/data-governance" routerLinkActive="active"
      >Data Governance</a
    >
  </li>
  <li>Data Tracking System Inventory</li>
</ul>
<h1>Data Tracking System Inventory</h1>
<table class="table table-bordered table-hover table-condensed">
  <thead>
    <tr>
      <th>Database Name</th>
      <th>Description</th>
      <th>Hosting Platform</th>
      <th>Owner (Office/Program)</th>
      <th>Point of Contact</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Access HelpLine</td>
      <td>
        Includes: Document call logs, providers, referrals, staff schedules,
        etc. Includes CSA Location and Phone Numbers, CSA Insurance Acceptance
        List, Important Numbers, Network Development Teams A/B, MHRS Speciality
        Providers, SUD Providers &amp; Services, Families First Program
        Information, CSA Enrollment Notification List, Peer Operated Centers,
        FSMHC Providers, 911 Diversion Logs (Answer log, Weekly total calls,
        Call disposition &amp; events)
      </td>
      <td>SharePoint</td>
      <td>Access HelpLine</td>
      <td>Dr. Boozer</td>
    </tr>
    <tr>
      <td>ASCEND</td>
      <td>Pharmacy application interfacing with Avatar</td>
      <td></td>
      <td>SEH, 35K and CPEP</td>
      <td></td>
    </tr>
    <tr>
      <td>Avatar</td>
      <td>SEH inpatient EHR interfacing with ASCEND</td>
      <td>Cloud (Netsmart)</td>
      <td>SEH</td>
      <td></td>
    </tr>
    <tr>
      <td>Certification DB</td>
      <td></td>
      <td>QuickBase</td>
      <td>Accountability Office</td>
      <td>Ayana Forbes</td>
    </tr>
    <tr>
      <td>Clinical Intake DB</td>
      <td>Used during intake</td>
      <td>QuickBase</td>
      <td>35K</td>
      <td>Nikiya Ford Jackson</td>
    </tr>
    <tr>
      <td>Co-located Service Branch DB</td>
      <td>Activities of certain clinicians</td>
      <td>QuickBase</td>
      <td></td>
      <td>Ray Kim</td>
    </tr>
    <tr>
      <td>Compliancy and Grievance DB</td>
      <td>
        Compliancy DB and Grievance DB are two different areas merged into one
      </td>
      <td>QuickBase</td>
      <td></td>
      <td>Nikiya Ford Jackson (Patricia Thompson)</td>
    </tr>
    <tr>
      <td>Consumer Satisfaction Survey DB</td>
      <td>Consumer satisfaction survey result documentation</td>
      <td>QuickBase</td>
      <td></td>
      <td>
        Nikiya Ford Jackson (Colin Billet) / Trian Logan / Jonathan Brooks
      </td>
    </tr>
    <tr>
      <td>CPEP Discharge Planning</td>
      <td>Discharge planning spreadsheet to support medication management</td>
      <td>Excel Spreadsheet</td>
      <td>CPEP</td>
      <td>Tamara Butler</td>
    </tr>
    <tr>
      <td>CSA Client Medication Orders</td>
      <td></td>
      <td>Excel Spreadsheet</td>
      <td>CSA</td>
      <td></td>
    </tr>
    <tr>
      <td>CSA Cost Spreadsheet</td>
      <td>Spreadsheet of CSA costs</td>
      <td>Excel Spreadsheet</td>
      <td>CPEP</td>
      <td>Tamara Butler</td>
    </tr>
    <tr>
      <td>DATA/WITS</td>
      <td>Substance use application (for claims and clinical documentation)</td>
      <td>Web-based (FEI)</td>
      <td>Substance Use providers</td>
      <td>Ti Valentine</td>
    </tr>
    <tr>
      <td>Dentrix</td>
      <td>SEH Dental application but also used for some outpatients (ARC)</td>
      <td>SQL</td>
      <td>SEH&amp; ARC</td>
      <td>Donald Clarke</td>
    </tr>
    <tr>
      <td>eRISK</td>
      <td>Incident Reporting</td>
      <td>DCGOV/ORM</td>
      <td>OA/Investigations</td>
      <td>Craig Stewart</td>
    </tr>
    <tr>
      <td>Expansion on School Mental Health DB</td>
      <td></td>
      <td>QuickBase</td>
      <td></td>
      <td>Charneta Scott</td>
    </tr>
    <tr>
      <td>Forensic DB</td>
      <td>
        Forensic program master database, including civil commitment cases.
        Track courthouse &amp; CSOSA record files;
      </td>
      <td>Excel Spreadsheet</td>
      <td>Forensics</td>
      <td>Chad Tillbrook&#39;s Team</td>
    </tr>
    <tr>
      <td>Glucometer</td>
      <td>Blood glucose level measure application</td>
      <td>Web-based</td>
      <td>SEH</td>
      <td></td>
    </tr>
    <tr>
      <td>Hand Hygiene DB</td>
      <td>Document hand hygine observation results</td>
      <td>QuickBase</td>
      <td>SEH</td>
      <td>Elain Tu</td>
    </tr>
    <tr>
      <td>Healthy Future DB</td>
      <td></td>
      <td>QuickBase</td>
      <td></td>
      <td>Monique Douglas</td>
    </tr>
    <tr>
      <td>Housing Program DB</td>
      <td>
        Housing development division&#39;s DB. Includes landlord, location,
        start date, income, tenant portion and subsidy portion. Move-out date is
        important for CRS (community residential service), level of care, reason
        for move-out, maintain vacancy and occupancy information. Consumers in
        CRS is in DB. Consumers in voucher program. (Data fed by list of clients
        from bread for the city, and CSA information, ACT data extract from
        iCAMS monthly.) Queries produce information going to HA (i.e. CRS
        vacancy report), payment adjustment, new placement report and KPI data.
      </td>
      <td>Access DB</td>
      <td>TBD</td>
      <td>Brandi Gladden</td>
    </tr>
    <tr>
      <td>iCAMS</td>
      <td>
        DBH outpatient EHR (for claim and clinical information) used by multiple
        entities, including DBH direct service divisions (35K &amp; CPEP),
        community providers, and St. E&#39;s SWs.
      </td>
      <td>Credible</td>
      <td>35K, CPEP, CSA, &amp; SEH SWs</td>
      <td>Samantha Campbell-Smith</td>
    </tr>
    <tr>
      <td>iCAMS - ePrescribe</td>
      <td>Pharmacy module in Credible</td>
      <td>Credible SureScript</td>
      <td>35K, CPEP</td>
      <td>iCAMS Helpdesk</td>
    </tr>
    <tr>
      <td>InCedo</td>
      <td>Claims processer for locally funded claims</td>
      <td>InfoMC</td>
      <td>DBH Billing/Claims</td>
      <td>Melvin Barry</td>
    </tr>
    <tr>
      <td>Licensing DB</td>
      <td>Store/track provider/facility licensing information</td>
      <td>QuickBase</td>
      <td></td>
      <td>Drew Sweat</td>
    </tr>
    <tr>
      <td>MMIS Claim Data Tracking Systems</td>
      <td>
        Manage and reconcile Medicaid and local claim data from iCAMS, WITS and
        MMIS data, including FFS claims from MMIS 835 remittance advice (RA),
        local claims from InCedo, and service data generated from iCAMS,
        Data/Wits
      </td>
      <td>Excle/Access DB</td>
      <td>Office of Operation</td>
      <td>Melvin Barry</td>
    </tr>
    <tr>
      <td>MMIS Eligibility File</td>
      <td>Eligibility file daily</td>
      <td>FTP</td>
      <td>iCAMS/Incedo</td>
      <td>TBD</td>
    </tr>
    <tr>
      <td>Operations Request DB</td>
      <td></td>
      <td>QuickBase</td>
      <td></td>
      <td>Raphaella Richardson</td>
    </tr>
    <tr>
      <td>Orchard</td>
      <td>Lab application</td>
      <td></td>
      <td>SEH</td>
      <td>Donald Clarke</td>
    </tr>
    <tr>
      <td>PASS</td>
      <td>Fiscal Year Purchase orders</td>
      <td>DCGOV</td>
      <td>Fiscal &amp; Ops</td>
      <td>Anthony Baffour</td>
    </tr>
    <tr>
      <td>Programs Integrity DB</td>
      <td>
        Used for claims auditing since 2016. Receive samples in excel (pulled
        from DHCF) and input into QuickBase to audit and rate.
      </td>
      <td>QuickBase</td>
      <td>Accountability Office</td>
      <td>Madonna Greene</td>
    </tr>
    <tr>
      <td>Pyxis</td>
      <td>
        Medication dispensing system Installed on each unit + TLC + Nursing
        office + 35K and CPEP. Interfacing with Avatar and ASCEND
      </td>
      <td></td>
      <td>SEH, 35K and CPEP</td>
      <td>Donald Clarke</td>
    </tr>
    <tr>
      <td>SOAR</td>
      <td>OCFO Financial System - Agency expenditures</td>
      <td>DCGOV</td>
      <td>Fiscal &amp; Ops</td>
      <td>Anthony Baffour</td>
    </tr>
    <tr>
      <td>SOR DB</td>
      <td>SUD grant management DB</td>
      <td>QuickBase</td>
      <td>SOR</td>
      <td>Julie Wiegandt</td>
    </tr>
    <tr>
      <td>SUD Prevention</td>
      <td></td>
      <td>QuickBase</td>
      <td></td>
      <td>Phillipa Stuart</td>
    </tr>
  </tbody>
</table>
