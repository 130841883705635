<ng-container *ngIf="errorInformation as info">
  <h3>Data Field Breakdown</h3>
  <div
    class="chart"
    [style.--fatal]="errorPercentages.fatal"
    [style.--criticalPosition]="errorPercentages.criticalPosition"
    [style.--critical]="errorPercentages.critical"
    [style.--warningPosition]="errorPercentages.warningPosition"
    [style.--warning]="errorPercentages.warning"
    [style.--validPosition]="errorPercentages.validPosition"
    [style.--valid]="errorPercentages.valid"
  ></div>
  <div class="legend">
    <div class="fatal">
      <span class="color-block"></span>
      <span
        >Fatal Error
        {{ errorInformation.fatal.totalCountOfFieldsWithError }}</span
      >
      <span>{{ errorInformation.fatal.relativeErrorCount.toFixed(2) }}%</span>
    </div>
    <div class="critical">
      <span class="color-block"></span>
      <span
        >Critical Error
        {{ errorInformation.critical.totalCountOfFieldsWithError }}</span
      >
      <span
        >{{ errorInformation.critical.relativeErrorCount.toFixed(2) }}%</span
      >
    </div>
    <div class="warning">
      <span class="color-block"></span>
      <span
        >Warning
        {{ errorInformation.warning.totalCountOfFieldsWithError }}</span
      >
      <span>{{ errorInformation.warning.relativeErrorCount.toFixed(2) }}%</span>
    </div>
    <div class="valid">
      <span class="color-block"></span>
      <span>Valid {{ dataFieldsWithoutErrors.length }} </span>
      <span>{{ validRelativeCount.toFixed(2) }}%</span>
    </div>
  </div>
</ng-container>
