<h3>Records Submitted: {{ totalRecords }}</h3>
<div
  class="chart"
  [style.--pass]="passPercentage + '%'"
  [style.--border]="passPercentage + (passPercentage > 0 ? 0.5 : 0) + '%'"
  [style.--fail]="failPercentage + '%'"
></div>
<div class="legend">
  <div class="pass">
    <span class="color-block"></span>
    <span>Pass {{ pass }}</span>
    <span>{{ passPercentage }}%</span>
  </div>
  <div class="fail">
    <span class="color-block"></span>
    <span>Fail {{ fail }}</span>
    <span>{{ failPercentage }}%</span>
  </div>
</div>
