<h1>External Data Resources</h1>
<p>
  The following are external links that are maintained outside of the ITE
  Portal, meant to be used as resources.
</p>

<h2>Public Behavioral Health Data</h2>
<ul>
  <li>
    <a href="https://www.datafiles.samhsa.gov/" target="_blank"
      >SAMHSA Substance Abuse and Mental Health Data Archive</a
    >
  </li>
  <li>
    <a href="https://www.cdc.gov/brfss/index.html" target="_blank"
      >CDC Behavioral Risk Factor Surveillance System (BRFSS)</a
    >
  </li>
  <li>
    <a
      href="https://www.cdc.gov/nchs/covid19/health-care-access-and-mental-health.htm"
      target="_blank"
      >CDC Household Pulse Survey</a
    >
  </li>
  <li>
    <a href="https://www.cdc.gov/nchs/index.htm" target="_blank"
      >National Center for Health Statistics</a
    >
  </li>
  <li>
    <a
      href="https://www.cdc.gov/healthyyouth/data/yrbs/index.htm"
      target="_blank"
      >Risk Behavior Surveillance System (YRBSS)</a
    >
  </li>
  <li>
    <a href="https://www.nimh.nih.gov/health/statistics" target="_blank"
      >NIH Mental Health Statistics</a
    >
  </li>
</ul>
<h2>District of Columbia Data</h2>
<ul>
  <li>
    <a href="https://www.dchealthmatters.org/" target="_blank"
      >DC Health Matters (Community Health Indicators)</a
    >
  </li>
  <li><a href="https://opendata.dc.gov/" target="_blank">Open Data DC</a></li>
  <li>
    <a
      href="https://dchealth.dc.gov/service/behavioral-risk-factor-surveillance-system"
      target="_blank"
      >DC Health BRFSS</a
    >
  </li>
  <li><a href="https://dckidscount.org/" target="_blank">DC KIDS Count</a></li>
</ul>
