<h1>Search & Query</h1>
<section>
  <h2>Client Search</h2>
  <p>Use the search to look up client record information.</p>
  <p>Search by full name, first name or last name.</p>
  <label for="client-search">Client Search</label>
  <input
    type="search"
    name="client-search"
    id="client-search"
    placeholder="Search"
    (change)="setSearchTerm($event)"
    (keyup.enter)="submitClientSearch()"
  />
  <button type="submit" class="button" (click)="submitClientSearch()">
    Search
  </button>
</section>
<section>
  <h2>Claim Search</h2>
  <p>
    Use the search to look up the master claim record table and view claim
    detail.
  </p>
  <p>Search by Claim ID (TCN) or Medicaid ID.</p>

  <label for="claim-search">Claim Search</label>
  <input
    type="search"
    name="claim-search"
    id="claim-search"
    placeholder="Search"
    [value]="searchTerm"
    (change)="setSearchTerm($event)"
    (keyup.enter)="submitClaimSearch()"
  />
  <button type="submit" class="button" (click)="submitClaimSearch()">
    Search
  </button>
  <p>
    <a routerLink="/search-and-query/claim-search/advanced-search" class=""
      >Advanced Claim Search</a
    >
  </p>
</section>
