import { Component } from '@angular/core';

@Component({
  selector: 'dbh-logo',
  templateUrl: './dbh-logo.component.html',
  styleUrls: ['./dbh-logo.component.scss'],
})
export class DbhLogoComponent {
  logo = true;
}
