<ul class="breadcrumbs">
  <li>
    <a routerLink="/search-and-query" routerLinkActive="active">Search</a>
  </li>
  <li><a routerLink="/search-and-query/claim-search">Claim Search</a></li>
  <li>Advanced Claim Search</li>
</ul>
<h1>Advanced Claim Search</h1>
<p>Show results matching all the following conditions.</p>
<div *ngFor="let criterion of criteria; let index = index">
  <div class="criterion">
    <div>
      <div>
        <label [for]="'identifier' + index">Identifier</label>
        <select
          [name]="'identifier' + index"
          [id]="'identifier' + index"
          [attr.data-id]="index"
          [value]="criterion.selector ?? ''"
          (change)="selectorSet($event)"
          required
        >
          <option value="" disabled="true">Selector</option>
          <option data-valueType="date" value="adjudication_date">
            Adjudication Date
          </option>
          <option
            data-valueType="date_range"
            value="first_date_of_service|last_date_of_service"
          >
            Service Period
          </option>
          <option data-valueType="text" value="billing_medicaid_id">
            Medicaid ID
          </option>
          <option data-valueType="select" value="adjudication_status">
            Status
          </option>
          <option data-valueType="select" value="procedure_code">
            Procedure
          </option>
          <option data-valueType="select" value="billing_provider_type_code">
            Provider Type
          </option>
          <option data-valueType="text" value="billing_provider">
            Provider Name
          </option>
        </select>
      </div>
    </div>
    <div>
      <div>
        <label [for]="'relative' + index">Relative</label>
        <select
          [name]="'relative' + index"
          [id]="'relative' + index"
          [attr.data-id]="index"
          [disabled]="!criterion.valueType"
          (change)="relativeSet($event)"
          [value]="criterion.relative ?? ''"
          required
        >
          <option value="" disabled="true">Relative</option>
          <ng-container
            *ngIf="
              criterion.valueType === 'date' ||
                criterion.valueType === 'date_range';
              else textSelect
            "
          >
            <option value="=" *ngIf="criterion.valueType === 'date'">Is</option>
            <option value="<">Is Before</option>
            <option value=">">Is After</option>
            <option value="><" *ngIf="criterion.valueType === 'date_range'">
              Contains
            </option>
          </ng-container>
          <ng-template #textSelect>
            <option value="=">Is</option>
            <option value="!=" *ngIf="criterion.valueType === 'select'">
              Is Not
            </option>
            <option value="LIKE" *ngIf="criterion.valueType === 'text'">
              Contains
            </option>
            <option value="NOT LIKE" *ngIf="criterion.valueType === 'text'">
              Does Not Contain
            </option>
          </ng-template>
        </select>
      </div>
    </div>
    <div>
      <div>
        <label [for]="'value' + index">Value</label>
        <ng-container *ngIf="criterion.valueType === 'select'; else nonSelect">
          <select
            [name]="'value' + index"
            [id]="'value' + index"
            [attr.data-id]="index"
            [disabled]="!criterion.relative"
            (change)="valueSet($event)"
            [value]="criterion.value ?? ''"
            style="width: 180px"
            required
          >
            <option value="" disabled="true">Select Value</option>
            <ng-container *ngIf="criterion.options; else asyncOptions">
              <ng-container *ngFor="let option of criterion.options">
                <option [value]="option.value">{{ option.display }}</option>
              </ng-container>
            </ng-container>
            <ng-template #asyncOptions>
              <ng-container *ngIf="criterion.asyncOptions | async as options">
                <ng-container *ngFor="let option of options">
                  <option [value]="option.value">{{ option.value }}</option>
                </ng-container>
              </ng-container>
            </ng-template>
          </select>
        </ng-container>
        <ng-template #nonSelect>
          <input
            [type]="
              criterion.valueType === 'date' ||
              criterion.valueType === 'date_range'
                ? 'date'
                : 'text'
            "
            [attr.data-id]="index"
            [disabled]="!criterion.relative"
            [name]="'value' + index"
            [id]="'value' + index"
            [value]="criterion.value ?? ''"
            (change)="valueSet($event)"
            (keyup)="valueSet($event)"
            style="width: 180px"
            [required]="
              criterion.valueType === 'date' ||
              criterion.valueType === 'date_range'
            "
          />
        </ng-template>
      </div>
    </div>
    <div *ngIf="criterion.selector &&
    criterion.valueType &&
    criterion.relative &&
    criterion.value">
      <button
        type="submit"
        class="button-outline"
        (click)="removeCondition(index)"
      >
        Remove Condition
      </button>
    </div>
  </div>
</div>
<button type="submit" class="add-button" (click)="addCondition()">
  <span>&#43;</span> Add Additional Condition
</button>
<br />
<button
  type="submit"
  class="button"
  (click)="submitAdvancedSearch()"
  [disabled]="searchDisabled"
>
  Advanced Search
</button>
<ng-container *ngIf="showClear || validCriteria.length > 2">
  <button
    type="button"
    class="button button-outline"
    (click)="showAll()"
  >
    Clear All Filters
  </button>
  </ng-container>
<ng-container *ngIf="searchResults$ | async as results">
  <hr />
  <h2>Search Results</h2>
  <ng-container *ngIf="results.claim_count === 0; else hasResults">
    <p>
      Your search returned 0 results, please try a different combination of
      search conditions.
    </p>
  </ng-container>

  <ng-template #hasResults>
    <table>
      <thead>
        <tr>
          <th>Claim ID (TCN - Line No.)</th>
          <th>Adjudication Date</th>
          <th>Medicaid ID</th>
          <th>Service Period</th>
          <th>Procedure Code</th>
          <th>Provider</th>
          <th>Provider Type</th>
          <th>Status</th>
          <th class="numeric">$ Billed</th>
          <th class="numeric">$ Payed</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let claim of results.claims">
          <td>
            <a [routerLink]="['/claims', claim.id]">{{
              claim.claim_record_id
            }}</a>
          </td>
          <td>{{ claim.adjudication_date | date : 'MM/dd/yyyy' }}</td>
          <td>{{ claim.billing_medicaid_id ?? '' }}</td>
          <td>
            {{ claim.first_date_of_service | date : 'MM/dd/yyyy' }}&ndash;{{
              claim.last_date_of_service | date : 'MM/dd/yyyy'
            }}
          </td>
          <td>{{ claim.procedure_code }}</td>
          <td class="wide">{{ claim.billing_provider ?? '' }}</td>
          <td>{{ claim.billing_provider_type_code }}</td>
          <td>{{ claim.adjudication_status }}</td>
          <td class="numeric">{{ claim.billed_amount | currency }}</td>
          <td class="numeric">{{ claim.paid_amount | currency }}</td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      Page
      <ng-container *ngFor="let pageNum of getPages(results.claim_count, page)">
        <a
          *ngIf="pageNum !== '...'"
          [ngClass]="{ active: page === pageNum.toString() }"
          [routerLink]=""
          (click)="updatePage(pageNum.toString())"
          >{{ pageNum }}
        </a>
        <div *ngIf="pageNum === '...'">...</div>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
