<ng-container *ngIf="fatalFields.length > 0">
  <h3>Fatal Errors (Key Fields)</h3>
  <table>
    <thead>
      <tr>
        <th>Field Name</th>
        <th class="numeric"># of Records</th>
        <th>Category (# of errors)</th>
      </tr>
    </thead>
    <tbody>
      <dbh-data-field-error-row
        *ngFor="let field of fatalFields | keyvalue : originalOrder"
        [dataField]="field.value"
        [countOfRecordsWithIssue]="getCountOfRecordsWithFieldError(field.value)"
        [categoriesWithError]="getCategoriesWithCount(field.value)"
        errorType="Fatal"
      ></dbh-data-field-error-row>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="criticalFields.length > 0">
  <h3>Critical Errors (Required Fields)</h3>
  <table>
    <thead>
      <tr>
        <th>Field Name</th>
        <th class="numeric"># of Records</th>
        <th>Category (# of errors)</th>
      </tr>
    </thead>
    <tbody>
      <dbh-data-field-error-row
        *ngFor="let field of criticalFields | keyvalue : originalOrder"
        [dataField]="field.value"
        [countOfRecordsWithIssue]="getCountOfRecordsWithFieldError(field.value)"
        [categoriesWithError]="getCategoriesWithCount(field.value)"
        errorType="Critical"
      ></dbh-data-field-error-row>
    </tbody>
  </table>
</ng-container>

<ng-container *ngIf="warningFields.length > 0">
  <h3>Warnings (Optional Fields)</h3>
  <table>
    <thead>
      <tr>
        <th>Field Name</th>
        <th class="numeric"># of Records</th>
        <th>Category (# of errors)</th>
      </tr>
    </thead>
    <tbody>
      <dbh-data-field-error-row
        *ngFor="let field of warningFields | keyvalue : originalOrder"
        [dataField]="field.value"
        [countOfRecordsWithIssue]="getCountOfRecordsWithFieldError(field.value)"
        [categoriesWithError]="getCategoriesWithCount(field.value)"
        errorType="Warning"
      ></dbh-data-field-error-row>
    </tbody>
  </table>
</ng-container>
