<td width="350px">{{ dataField }}</td>
<td width="150px" class="numeric">{{ countOfRecordsWithIssue }}</td>
<td>
  <span *ngFor="let category of categoriesWithError; index as i"
    >{{ category.category }} ({{ category.count }})<ng-container
      *ngIf="i < categoriesWithError.length - 1"
      >,
    </ng-container>
  </span>
</td>
